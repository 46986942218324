<template>
  <div class="order-right col-12 col-md-6">
    <div v-if="form && form.order_history && form.order_history.length">
      <ul
        class="order-history__list"
        v-for="(item, index) in form.order_history.filter(
          (item) =>
            item.operation === 'edit' ||
            item.operation === 'updateOrder' ||
            item.operation === 'updateOrder1C' ||
            item.binotel_id
        )"
        :key="item.id + index + Math.floor(Math.random() * 100)"
      >
        <li class="order-history__list-items">
          <ul class="order-history__item-left">
            <!-- імя покупця -->
            <li
              v-if="item.data && item.data.data && item.data.data.customer_name"
            >
              <span class="order-history__item-title">
                {{ $t("history_order.buyer") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.customer_name.old"
              >
                {{ item.data.data.customer_name.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.customer_name.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.customer_name.new }}
              </span>
            </li>
            <!-- телефон -->
            <li v-if="item.data && item.data.data && item.data.data.phone">
              <span class="order-history__item-title">
                {{ $t("global.phone ") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.phone.old"
              >
                <span v-if="item.data.data.phone.old.length">{{
                  item.data.data.phone.old
                }}</span>
                <span v-else>0</span>
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.phone.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.phone.new }}
              </span>
            </li>
            <!-- емейл -->
            <li v-if="item.data && item.data.data && item.data.data.email">
              <span class="order-history__item-title">
                email:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.email.old"
              >
                {{ item.data.data.email.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.email.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.email.new }}
              </span>
            </li>
            <!-- відділ -->
            <li
              v-if="item.data && item.data.data && item.data.data.department_id"
            >
              <span class="order-history__item-title">
                {{ $t("global.department") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data && item.data.data.department_id.old"
              >
                {{
                  historyOrderDepartmentType(item.data.data.department_id.old)
                }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.department_id.old"
              ></i>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.department_id.new"
              >
                {{
                  historyOrderDepartmentType(item.data.data.department_id.new)
                }}
              </span>
            </li>
            <!-- статус замовлення -->
            <li
              v-if="
                item.data && item.data.data && item.data.data.order_status_id
              "
            >
              <span class="order-history__item-title">
                {{ $t("history_order.order_status") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.order_status_id.old"
              >
                {{ historyOrderStatusType(item.data.data.order_status_id.old) }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.order_status_id.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ historyOrderStatusType(item.data.data.order_status_id.new) }}
              </span>
            </li>
            <!-- статус замовлення №2 -->
            <li v-if="item.data && item.data.order_status_id">
              <span class="order-history__item-title">
                {{ $t("history_order.order_status") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.order_status_id.old"
              >
                {{ historyOrderStatusType(item.data.order_status_id.old) }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.order_status_id.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ historyOrderStatusType(item.data.order_status_id.new) }}
              </span>
            </li>
            <!-- причина відміни -->
            <li
              v-if="item.data && item.data.data && item.data.data.cancel_reason"
            >
              <span class="order-history__item-title">
                {{ $t("history_order.cancel_type") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.cancel_reason.old"
              >
                {{ historyOrderCancelType(item.data.data.cancel_reason.old) }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.cancel_reason.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ historyOrderCancelType(item.data.data.cancel_reason.new) }}
              </span>
            </li>
            <!-- спосіб оплати -->
            <li
              v-if="
                item.data && item.data.data && item.data.data.payment_method_id
              "
            >
              <span class="order-history__item-title">
                {{ $t("history_order.payment_type") }}
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.payment_method_id.old"
              >
                {{
                  historyOrderPaymentMethod(
                    item.data.data.payment_method_id.old
                  )
                }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.payment_method_id.old"
              ></i>
              <span class="order-history__item-value blue">
                {{
                  historyOrderPaymentMethod(
                    item.data.data.payment_method_id.new
                  )
                }}
              </span>
            </li>
            <!-- комментар -->
            <li v-if="item.data && item.data.data && item.data.data.comment">
              <span class="order-history__item-title">
                {{ $t("global.comment") }}:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.comment.old"
              >
                {{ item.data.data.comment.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.comment.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.comment.new }}
              </span>
            </li>
            <!-- комментар менеджера -->
            <li
              v-if="
                item.data && item.data.data && item.data.data.manager_comment
              "
            >
              <span class="order-history__item-title">
                Коментарій менеджера:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.manager_comment.old"
              >
                {{ item.data.data.manager_comment.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.manager_comment.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.manager_comment.new }}
              </span>
            </li>
            <!-- Коментар для кладовщика -->
            <li
              v-if="
                item.data && item.data.data && item.data.data.comment_warehouse
              "
            >
              <span class="order-history__item-title">
                Коментар для кладовщика:
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.comment_warehouse.old"
              >
                {{ item.data.data.comment_warehouse.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.comment_warehouse.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.comment_warehouse.new }}
              </span>
            </li>
            <!-- спосіб доставки -->
            <li
              v-if="
                item.data && item.data.data && item.data.data.shipment_method_id
              "
            >
              <span class="order-history__item-title">
                {{ $t("history_order.delivery_type") }}
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.data.shipment_method_id.old"
              >
                {{ historyDelivery(item.data.data.shipment_method_id.old) }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.data.shipment_method_id.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ historyDelivery(item.data.data.shipment_method_id.new) }}
              </span>
            </li>
            <!-- спосід доставки №2 -->
            <li
              v-if="
                item.data &&
                  item.data.shipment &&
                  item.data.shipment.type_delivery
              "
            >
              <span class="order-history__item-title">
                {{ $t("history_order.delivery_type") }}
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.type_delivery.old"
              >
                {{ historyDeliveryType(item.data.shipment.type_delivery.old) }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.type_delivery.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ historyDeliveryType(item.data.shipment.type_delivery.new) }}
              </span>
            </li>
            <!-- оплата доставки -->
            <li
              v-if="
                item.data &&
                  item.data.shipment &&
                  item.data.shipment.type_payment
              "
            >
              <span class="order-history__item-title">
                Оплата доставки
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.type_payment.old"
              >
                {{
                  historyDeliveryShipmentType(
                    item.data.shipment.type_payment.old
                  )
                }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.type_payment.old"
              ></i>
              <span class="order-history__item-value blue">
                {{
                  historyDeliveryShipmentType(
                    item.data.shipment.type_payment.new
                  )
                }}
              </span>
            </li>
            <!-- Область -->
            <li
              v-if="
                item.data && item.data.shipment && item.data.shipment.area_name
              "
            >
              <span class="order-history__item-title">
                Область
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.area_name.old"
              >
                {{ item.data.shipment.area_name.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.area_name.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.area_name.new }}
              </span>
            </li>
            <!-- Місто -->
            <li
              v-if="
                item.data && item.data.shipment && item.data.shipment.city_name
              "
            >
              <span class="order-history__item-title">
                Місто
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.city_name.old"
              >
                {{ item.data.shipment.city_name.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.city_name.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.city_name.new }}
              </span>
            </li>
            <!-- Відділ/Індекс -->
            <li
              v-if="
                item.data &&
                  item.data.shipment &&
                  item.data.shipment.warehouse_name
              "
            >
              <span class="order-history__item-title">
                Відділ/Index
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.warehouse_name.old"
              >
                {{ item.data.shipment.warehouse_name.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.warehouse_name.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.warehouse_name.new }}
              </span>
            </li>
            <!-- Вулиця -->
            <li
              v-if="
                item.data &&
                  item.data.shipment &&
                  item.data.shipment.street_name
              "
            >
              <span class="order-history__item-title">
                Вулиця
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.street_name.old"
              >
                {{ item.data.shipment.street_name.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.street_name.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.street_name.new }}
              </span>
            </li>
            <!-- дім -->
            <li
              v-if="
                item.data && item.data.shipment && item.data.shipment.house_name
              "
            >
              <span class="order-history__item-title">
                {{ $t("history_order.house") }}
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.house_name.old"
              >
                {{ item.data.shipment.house_name.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.house_name.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.house_name.new }}
              </span>
            </li>
            <!-- Квартира -->
            <li
              v-if="
                item.data && item.data.shipment && item.data.shipment.apartment
              "
            >
              <span class="order-history__item-title">
                Квартира
              </span>
              <span
                class="order-history__item-value blue"
                v-if="item.data.shipment.apartment.old"
              >
                {{ item.data.shipment.apartment.old }}
              </span>
              <i
                class="fa fa-long-arrow-right"
                v-if="item.data.shipment.apartment.old"
              ></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.apartment.new }}
              </span>
            </li>
            <!-- ТТН -->
            <li v-if="item.data && item.data.data && item.data.data.ttn">
              <span class="order-history__item-title">
                TTH:
              </span>
              <span class="order-history__item-value blue">
                {{ item.data.data.ttn.old }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.ttn.new }}
              </span>
            </li>
            <!-- Співробітник -->
            <li v-if="item.data && item.data.employee_id">
              <span class="order-history__item-title">
                {{ $t("history_order.employee") }}
              </span>
              <span class="order-history__item-value blue">
                {{ historyEmployee(item.data.employee_id) }}
              </span>
            </li>
            <!-- Співробітник №2 -->
            <li
              v-if="item.data && item.data.data && item.data.data.employee_id"
            >
              <span class="order-history__item-title">
                {{ $t("history_order.employee") }}
              </span>
              <span class="order-history__item-value blue">
                {{ historyEmployee(item.data.data.employee_id.old) }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-value blue">
                {{ historyEmployee(item.data.data.employee_id.new) }}
              </span>
            </li>
            <!-- ІД замовлення -->
            <li v-if="item.data && item.data.data && item.data.data.order_id">
              <span class="order-history__item-title">
                order_id:
              </span>
              <span class="order-history__item-value blue">
                {{ item.data.data.order_id.old }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.order_id.new }}
              </span>
            </li>
            <!-- ІП -->
            <li v-if="item.data && item.data.data && item.data.data.ip">
              <span class="order-history__item-title">
                ip:
              </span>
              <span class="order-history__item-value blue">
                {{ item.data.data.ip.old }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-value blue">
                {{ item.data.data.ip.new }}
              </span>
            </li>
            <!-- сумма предоплати -->
            <li
              v-if="
                item.data &&
                  item.data.shipment &&
                  item.data.shipment.prepayment_amount
              "
            >
              <span class="order-history__item-title">
                {{ $t("history_order.count_prepayment") }}
              </span>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.prepayment_amount.old }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-value blue">
                {{ item.data.shipment.prepayment_amount.new }}
              </span>
            </li>
            <!-- стан -->
            <li
              v-if="item.data && item.data.shipment && item.data.shipment.state"
            >
              <span class="order-history__item-title">
                {{ item.data.shipment.state }}
              </span>
            </li>
            <!-- Смс -->
            <li v-if="item.data && item.data.send_client_sms">
              <span class="order-history__item-title">
                {{ $t("history_order.sms_message") }}:
              </span>
              <span
                class="order-history__item-value orange"
                v-if="item.data.message_id === null"
              >
                Не {{ $t("history_order.sent") }}
              </span>
              <span
                class="order-history__item-value green"
                v-if="item.data.message_id && item.data.message_id.length > 1"
              >
                {{ $t("history_order.sent") }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-message">
                {{ item.data.send_client_sms }}
              </span>
            </li>
            <!-- телефон -->
            <li v-if="item.data && item.data.phone">
              <span class="order-history__item-title">
                Телефон:
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-message">
                {{ item.data.phone }}
              </span>
            </li>
            <!-- Повне імя клієнта -->
            <li v-if="item.data && item.data.client_full_name">
              <span class="order-history__item-title">
                Ім'я клієнту:
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-message">
                {{ item.data.client_full_name }}
              </span>
            </li>
            <!-- Загальна ціна -->
            <li v-if="item.data && item.data.data && item.data.data.total_cost">
              <span class="order-history__item-title">
                Загальна ціна:
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-message">
                {{ item.data.data.total_cost.new }}
              </span>
            </li>
            <!-- Товари -->
            <li v-if="item.data && item.data.products">
              <ul>
                <li
                  v-for="(itemProduct, indexProduct) in item.data.products"
                  :key="indexProduct"
                >
                  <span class="order-history__item-title">
                    Товар
                  </span>
                  <span class="order-history__item-value green">
                    {{ itemProduct.state }}
                  </span>
                  <i class="fa fa-long-arrow-right"></i>
                  <span class="order-history__item-message">
                    <span v-if="itemProduct.title">{{
                      itemProduct.title
                    }}</span>
                    <span
                      v-if="itemProduct.quantity && itemProduct.quantity.news"
                    >
                      - {{ itemProduct.quantity.new }} шт.}</span
                    >
                    <span v-if="itemProduct.price && itemProduct.price.news"
                      >{{ itemProduct.price.new }}грн.</span
                    >
                  </span>
                </li>
              </ul>
            </li>
            <!-- Дзвінок-->
            <li v-if="item.binotel_id">
              <span class="order-history__item-title">
                Дзвінок:
              </span>
              <span class="order-history__item-value blue">
                {{ item.internal_number }}
              </span>
              <i class="fa fa-long-arrow-right"></i>
              <span class="order-history__item-value blue">
                {{ item.external_number }}
              </span>
              <span class="order-history__item-value">
                Статус: {{ showCallStatus(item.status) }}
              </span>
              <span class="order-history__item-value">
                Тип: {{ showCallType(item.call_type) }}
              </span>
              <span v-if="item.status === 'ANSWER'">
                <span v-if="item.record" class="order-history__call-link">
                  <a :href="item.record" target="_blank">Запис розмови</a>
                </span>
                <input
                  v-else
                  class="order-history__get-call btn btn-primary"
                  style="height: 25px; line-height: 13px;"
                  type="button"
                  value="Отримати запис"
                  @click="getBinotelCallLink(item.binotel_id)"
                />
              </span>
            </li>
            <!-- Дата зміни-->
            <li class="order-history__item-date">
              <span>
                {{ historyDate(item.created_at) }}
              </span>
            </li>
          </ul>
          <div
            class="order-history__item-icon blue"
            v-if="item.data && item.data.data"
          >
            <span class="order-history__info-icon" />
          </div>
          <div
            class="order-history__item-icon blue"
            v-if="item.data && item.data.send_client_sms"
          >
            <span class="order-history__phone-icon">
              <PhoneIcon :fillColor="'#689CE7'" />
            </span>
          </div>

          <div class="order-history__item-icon blue" v-if="item.binotel_id">
            <span class="order-history__phone-icon">
              <PhoneIcon :fillColor="'#689CE7'" />
            </span>
          </div>
        </li>
      </ul>
      <ul
        class="order-history__list"
        v-for="(item, index) in form.order_history.filter(
          (item) => item.operation === 'create'
        )"
        :key="index"
      >
        <li class="order-history__list-items">
          <ul class="order-history__item-left">
            <li>
              <span class="order-history__item-title">
                Замовлення створене <br /><b>{{ item.member }}</b>
                <span v-if="item.order_id">({{ item.order_id }})</span>
              </span>
            </li>
            <li class="order-history__item-date">
              <span>
                {{ historyDate(item.created_at) }}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else>{{ $t("history_order.empty_history") }}.</div>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";
import icons from "../../../../mixins/icons";

export default {
  props: ["form"],
  mixins: [icons],
  data() {
    return {
      deliveryType: [],
      cancelReasonList: [
        {
          id: "0",
          title: "-Нет значения-",
        },
        {
          id: "1",
          title: "Дубль",
        },
        {
          id: "2",
          title: "Відмовилися",
        },
        {
          id: "3",
          title: "Не робив замовлення",
        },
        {
          id: "4",
          title: "Недійсний номер",
        },
        {
          id: "5",
          title: "Недооформлені замовлення",
        },
        {
          id: "6",
          title: "Повернення на склад",
        },
      ],
      typeNpDelivery: [
        {
          title: "Отделение",
          id: "WarehouseWarehouse",
        },
        {
          title: "Адрес",
          id: "WarehouseDoors",
        },
      ],
      order_calls: null,
      shipmentList: [
        {
          id: "standard",
          title: "Стандарт",
        },
        {
          id: "free",
          title: "За наш рахунок",
        },
        {
          id: "bonus",
          title: "Бонус",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      orderStatusList: "orders/orderStatusList",
      shipmentMethods: "orders/shipmentMethods",
      callLinkData: "templates/callLinkData",
      paymentMethods: "orders/paymentMethods",
      departments: "catalog/departments",
      users: "contacts/users",
    }),
  },
  created() {
    this.shipmentMethods.data.map((item) => {
      this.deliveryType.push(item);
    });
    if (this.form.order_history) {
      this.validateOrderCalls();
    }
  },
  watch: {
    callLinkData(e) {
      this.form.order_history.map((item) => {
        if (item.binotel_id && item.binotel_id === e.binotel_id) {
          item.record = e.result.models;
        }
      });
      this.$forceUpdate();
    },
    form(e) {
      if (e.order_calls) {
        this.validateOrderCalls();
      }
    },
  },
  methods: {
    validateOrderCalls() {
      this.order_calls = [];
      this.form.order_calls.map((item) => {
        if (item.binotel_id) {
          this.order_calls.push({
            internal_number: item.internal_number,
            external_number: item.external_number,
            status: item.status,
            record: null,
            binotel_id: item.binotel_id,
            created_at: item.created_at,
          });
        }
      });
    },
    showCallStatus(status) {
      return status === "BUSY"
        ? "Зайнято"
        : status === "ANSWER"
        ? "Відповідь"
        : status === "CANCEL"
        ? "Відміна"
        : status === "CHANUNAVAIL"
        ? "Невірний номер"
        : status;
    },
    showCallType(type) {
      return type === 0 ? "Вхідний" : "Вихідний";
    },
    historyDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    historyDelivery(id) {
      const resultType = this.deliveryType.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      )[0];
      return resultType.title;
    },
    historyDeliveryType(id) {
      if (id) {
        const resultType = this.typeNpDelivery.filter(
          (deliveryTypeItem) => deliveryTypeItem.id === id
        )[0];
        return resultType.title;
      } else {
        return "-";
      }
    },
    historyDeliveryShipmentType(id) {
      if (id) {
        const resultType = this.shipmentList.filter(
          (deliveryTypeItem) => deliveryTypeItem.id === id
        )[0];
        return resultType.title;
      } else {
        return "-";
      }
    },
    historyOrderStatusType(id) {
      const resultType = this.orderStatusList.data.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      );
      return resultType[0] ? resultType[0].title : "-";
    },
    historyOrderDepartmentType(id) {
      const resultType = this.departments.data.filter(
        (departmentstem) => departmentstem.id === id
      )[0];
      return resultType.title;
    },
    historyOrderCancelType(id) {
      const resultType = this.cancelReasonList.filter(
        (departmentstem) => Number(departmentstem.id) === Number(id)
      )[0];
      return resultType.title;
    },
    historyOrderPaymentMethod(id) {
      const resultType = this.paymentMethods.data.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      )[0];
      return resultType.title;
    },
    historyEmployee(id) {
      const resultType = this.users.filter((userItem) => userItem.id === id)[0];
      return resultType ? resultType.name : "-";
    },
    getBinotelCallLink(binotel_id) {
      this.getCallLink(binotel_id).then((res) => {
        if (res.status) {
          this.$toasted.success(res.data.message, {
            duration: 3000,
          });
        } else {
          this.$toasted.error(res.data.message, {
            duration: 3000,
          });
        }
      });
    },
    ...mapActions({
      getCallLink: "templates/getCallLink",
    }),
  },
};
</script>
<style lang="scss" scoped>
.order-right {
  max-height: 100vh;
  overflow-y: auto;
  @media screen and (max-width: 480px) {
    overflow-y: visible;
    max-height: 100%;
  }
}
.order-history {
  &__list {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 10px;
  }
  &__list-items {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    background: white;
    margin-bottom: 10px;
    @media screen and (max-width: 1280px) {
      overflow-x: scroll;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item-left {
    li {
      display: block;
      margin: 5px 0;
      @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 5px;
      }
    }
    .fa.fa-long-arrow-right {
      color: #689ce7;
      @media screen and (max-width: 480px) {
        transform: rotate(90deg);
      }
    }
  }
  &__item-title {
    color: #535c69;
    font-size: 12px;
    font-weight: bold;
  }
  &__item-value {
    font-size: 12px;
    font-weight: bold;
    border-radius: 13px;
    padding: 3px 9px;
    margin: 0 5px;
    background: #eaeaea;
    white-space: nowrap;

    &.blue {
      color: #689ce7;
      background: #dbe9fd;
    }
    &.green {
      color: #7aac00;
      background: #e4f4d3;
    }
    &.orange {
      color: #b0750d;
      background: #fff3d6;
    }
  }
  &__item-message {
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    padding: 2px 7px;
    display: inline-block;
    margin: 2px 0px;
    border: 1px solid #eef2f4;
    white-space: break-spaces;
  }
  &__item-date {
    position: absolute;
    right: 10px;
    bottom: 5px;
    span {
      color: #b3b3b3;
      font-size: 13px;
      font-weight: bold;
    }
    @media screen and (max-width: 480px) {
      span {
        font-size: 10px;
      }
    }
  }
  &__info-icon {
    height: 20px;
    width: 20px;
    background: #689ce7;
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    &:before {
      content: "i";
      color: white;
      font-size: 13px;
      font-weight: bold;
      line-height: 1.7;
    }
  }
  &__phone-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      height: 25px;
      width: 25px;
    }
  }
  &__get-call.btn {
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
    padding-top: 5px;
  }
  &__call-link {
    a {
      font-size: 12px;
    }
  }
}
</style>
